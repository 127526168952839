import {
  SET_PLANILLA,
  SET_DOCUMENTS,
  SET_FACTOR,
  GET_EDITED_DOCUMENT,
  SET_EDITED_DOCUMENT,
  EDIT_DOCUMENT,
  APPROVE_EDITED,
  CHANGE_PLANILLA,
  GET_PLANILLA,
  SEARCH_PLANILLA,
  UPLOAD_PLANILLA,
  ADD_BANDA,
  GET_BANDA,
  GET_BANDA_POR_SEXO,
  UPDATE_BANDA,
  DELETE_BANDA,
  CREATE_DOCUMENTS,
  GET_DOCUMENTS,
  APPROVE_DOCUMENTS,
  DELETE_DOCUMENTS,
  UPDATE_DOCUMENTS,
  SET_ADMIN_SUSTENTO,
  SET_SUSTENTO,
  SEARCH_SUSTENTO,
  UPDATE_SUSTENTO,
  UPDATE_SUSTENTOS,
  GET_FACTOR,
  UPDATE_FACTOR,
  ADD_FACTOR,
  DELETE_FACTOR,
  GET_REPORTE,
  SEND_REPORTE,
  UPLOAD_PONDERACION,
  LOGOUT_USER,
  GET_DESCRIPCION_EMPRESA,
  GET_EXIST_ORGANIGRAMA
} from "../actions/Types";
let nullState = {
  workers: [],
  sustentos: [],
  planillas: [],
  bandas: [],
  bandasPorSexo: [],
  documentos: [],
  factores: [],
  reportes: [],
  edited: [],
  currentEdited: {},
  currentSustento: {},
  currentFactor: {},
  politicas: [],
  existorganigrama: false
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case UPLOAD_PLANILLA:
      return {
        ...state,
        workers: payload.planilla.workers,
        planillas: [payload.planilla, ...state.planillas],
        factores: payload.factores
      };
    case GET_EXIST_ORGANIGRAMA:
      return {
        ...state,
        existorganigrama: payload
      };
    case GET_PLANILLA:
      return {
        ...state,
        workers: payload[0].workers,
        sustentos: payload[0].sustentos && payload[0].sustentos,
        planillas: payload
      };
    case SET_PLANILLA:
    case SEARCH_PLANILLA:
      return {
        ...state,
        workers: payload[0].workers,
        sustentos: payload[0].sustentos && payload[0].sustentos
      };
    case CHANGE_PLANILLA:
      const selectedPlanilla = state.planillas.filter(
        planilla => planilla._id === payload
      );
      return {
        ...state,
        workers: selectedPlanilla[0].workers,
        sustentos:
          selectedPlanilla[0].sustentos && selectedPlanilla[0].sustentos
      };

    case SET_DOCUMENTS:
    case GET_DOCUMENTS:
    case CREATE_DOCUMENTS:
      return {
        ...state,
        documentos: payload
      };

    case GET_DESCRIPCION_EMPRESA:
      return {
        ...state,
        politicas: payload
      };
    case DELETE_DOCUMENTS:
      return {
        ...state,
        documentos: state.documentos.filter(doc => doc._id !== payload._id)
      };
    case APPROVE_DOCUMENTS:
    case UPDATE_DOCUMENTS:
    case APPROVE_EDITED:
      return {
        ...state,
        documentos: state.documentos.map(doc =>
          doc._id === payload._id ? payload : doc
        )
      };
    case GET_EDITED_DOCUMENT:
      return {
        ...state,
        edited: payload
      };
    case SET_EDITED_DOCUMENT:
      return {
        ...state,
        currentEdited: state.edited.filter(edited => edited._id === payload)[0]
      };
    case EDIT_DOCUMENT:
      return {
        ...state,
        edited: [payload, ...state.edited]
      };
    case GET_BANDA:
      return {
        ...state,
        bandas: payload
      };
    case GET_BANDA_POR_SEXO:
      return {
        ...state,
        bandasPorSexo: payload
      };
    case ADD_BANDA:
      return {
        ...state,
        bandas: [...state.bandas, payload.banda],
        sustentos: payload.planilla.sustentos
      };
    case UPDATE_BANDA:
      return {
        ...state,
        sustentos: payload.planilla.sustentos,
        bandas: state.bandas.map(banda =>
          banda._id === payload.banda._id ? payload.banda : banda
        )
      };
    case DELETE_BANDA:
      return {
        ...state,
        bandas: state.bandas.filter(banda => banda._id !== payload._id)
      };
    case SET_ADMIN_SUSTENTO:
      return {
        ...state,
        currentSustento: payload
      };
    case SET_SUSTENTO:
      return {
        ...state,
        currentSustento: state.sustentos.filter(e => e._id === payload)[0]
      };
    case SEARCH_SUSTENTO:
      return {
        ...state,
        sustentos: state.sustentos.filter(
          sustento =>
            (sustento.cargo.includes(payload) ||
              sustento.nombre.includes(payload) ||
              sustento.banda.includes(payload)) &&
            sustento
        )
      };
    case UPDATE_SUSTENTO:
      state.sustentos[
        state.sustentos.findIndex(e => e._id === payload._id)
      ] = payload;
      return state;
    case UPDATE_SUSTENTOS:
      return {
        ...state,
        sustentos: payload
      };

    case SET_FACTOR:
      return {
        ...state,
        currentFactor: state.factores.filter(e => e._id === payload)[0]
      };
    case GET_FACTOR:
      return {
        ...state,
        factores: payload
      };
    case UPDATE_FACTOR:
      return {
        ...state,
        factores: state.factores.map(factor =>
          factor._id === payload._id ? payload : factor
        )
      };
    case ADD_FACTOR:
      return {
        ...state,
        factores: [...state.factores, payload]
      };
    case DELETE_FACTOR:
      return {
        ...state,
        factores: state.factores.filter(factor => factor._id !== payload._id)
      };

    case UPLOAD_PONDERACION:
      return {
        ...state,
        workers: payload.planilla.workers,
        sustentos: payload.planilla.sustentos,
        bandas: payload.bandas
      };

    case GET_REPORTE:
    case SEND_REPORTE:
      return {
        ...state,
        reportes: payload
      };

    case LOGOUT_USER:
      return nullState;
    default:
      return state;
  }
};
