import { GET_CARGOS_DE_PAGO } from "../actions/Types";
let initialState = {
  loading: false,
  cargos: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_CARGOS_DE_PAGO:
      return {
        ...state,
        cargos: payload
      };
    default:
      return state;
  }
};
