import "./AppRenderer.css";
import { setPreloadedState } from "./redux/actions/User";
import { CssBaseline } from "@material-ui/core";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

export const renderApp = (preloadedState) =>
  ReactDOM.render(
    <Provider store={configureStore(preloadedState)}>
      <Suspense fallback={<div className="loading-hcp" />}>
        <CssBaseline />
        <App />
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );
(async () => renderApp(await setPreloadedState()))();
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
