import {
  LOGOUT_USER,
  GET_WIKI,
  CREATE_WIKI,
  UPDATE_WIKI,
  DELETE_WIKI
} from "../actions/Types";
let nullState = [];

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_WIKI:
      return payload;
    case UPDATE_WIKI:
      return state.map(e => (e._id === payload._id ? payload : e));
    case DELETE_WIKI:
      return state.filter(e => e._id !== payload._id);
    case CREATE_WIKI:
      return [...state, payload];
    case LOGOUT_USER:
      return nullState;
    default:
      return state;
  }
};
