import { GET_PATH_LOCATION } from "../actions/Types";
let nullState = {
  location: "",
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_PATH_LOCATION:
      return {
        ...state,
        location: payload,
      };
    default:
      return state;
  }
};
