import {
  GET_EMPLEOS,
  CREATE_EMPLEO,
  UPDATE_EMPLEO,
  DELETE_EMPLEO,
  DELETE_POSTULACION,
  GET_POSTULACION,
  //actualiza el flag de archivo
  UPDATE_FLAG_EMPLEO,
  // eslint-disable-next-line
  UPDATE_ESTADO_EMPLEO,
  GET_PRUEBAS,
  ADD_PRUEBA,
  LOGOUT_USER,
  GET_ALL_EMPRESAS,
  CREATE_EXPERIENCIA_CLIENTE,
  GET_EXPERIENCIA_CLIENTE,
  SEARCH_EMPLEO,
  GET_POSTULACION_BY_EMPLEO,
  GET_ALL_ESTUDIOS,
  SET_POSTULACIONES_LOADING,
  FETCH_POSTULATIONS,
  FETCH_POSTULATIONS_SUCCESS,
  FETCH_POSTULATIONS_ERROR,
  UPDATE_POSTULATIONS,
  UPDATE_POSTULATIONS_SUCCESS,
  UPDATE_POSTULATIONS_ERROR,
  GET_ALL_EMPLEOS_FAVORITOS,
  ADD_EMPLEO_FAVORITO,
  REMOVE_EMPLEO_FAVORITO,
  SEARCH_EMPLEOS_BY_FECHA,
  GET_EMPLEO_BY_ID,
  CREATE_POSTULACION,
  GET_ALL_RECOMMENDED_JOBS,
  REMOVE_RECOMMENDED_JOBS,
  GET_ALL_EMPLOYMENTS,
  GET_ALL_FILTERED_EMPLOYMENTS,
  SET_TXTSEARCH,
  GET_PLAN_SELECTION,
  GET_PLAN_SELECTION_CHOICE,
  EXPIRED_EMPLOYMENTS,
  VALIDATE_SELECTIONUSER_MAIL,
  GET_PLATFORM_SCORE,
  UPDATE_POSTULACION_ENTREVISTA,
  CREATE_EVALUACION,
  GET_EVALUACION_BY_ID,
  GET_INVITACIONES_BY_EMAIL,
  UPDATE_ESTADO_INVITACION
} from "../actions/Types";

let nullState = {
  empleos: [],
  allEmpleosFavoritos: [],
  allRecommendedJobs: [],
  pruebasTerminadas: [],
  allPostulaciones: [],
  allPostulacionesLoading: false,
  errorPostulaciones: null,
  allEmpresas: [],
  allEstudios: [],
  experienciaCliente: {},
  postuloEmpleo: {},
  empleo: {},
  //v2
  allEmployments: [],
  expiredEmployments: [],
  filteredEmployments: [],
  txtSearch: "",
  plan: null,
  selectionUserMail: false,
  platformScore: 1,
  //evaluaciones
  evaluaciones: [],
  evaluacion: {},
  evaluacionTest: null,
  allInvitacionesEvaluacion: [],
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case UPDATE_POSTULATIONS:
      return {
        ...state,
        allPostulacionesLoading: true,
      };
    case UPDATE_POSTULATIONS_SUCCESS:
      return {
        ...state,
        allPostulacionesLoading: false,
      };
    case UPDATE_POSTULATIONS_ERROR:
      return {
        ...state,
        allPostulacionesLoading: false,
      };
    case FETCH_POSTULATIONS:
      return {
        ...state,
        allPostulacionesLoading: true,
      };
    case FETCH_POSTULATIONS_SUCCESS:
      return {
        ...state,
        allPostulacionesLoading: false,
        allPostulaciones: payload,
      };
    case FETCH_POSTULATIONS_ERROR:
      return {
        ...state,
        allPostulacionesLoading: false,
        errorPostulaciones: payload || null,
      };
    case SET_POSTULACIONES_LOADING:
      return {
        ...state,
        allPostulacionesLoading: payload,
      };
    case CREATE_EMPLEO:
      return {
        ...state,
        empleos: [payload, ...state.empleos],
      };
    case GET_ALL_EMPLEOS_FAVORITOS:
      return {
        ...state,
        allEmpleosFavoritos: payload,
      };
    case GET_EMPLEOS:
      return {
        ...state,
        empleos: payload,
      };
    case GET_ALL_EMPLOYMENTS:
      return {
        ...state,
        allEmployments: payload,
      };
    case GET_ALL_FILTERED_EMPLOYMENTS:
      return {
        ...state,
        filteredEmployments: payload,
      };
    case SET_TXTSEARCH: {
      return {
        ...state,
        txtSearch: payload,
      };
    }
    case GET_ALL_RECOMMENDED_JOBS:
      return {
        ...state,
        allRecommendedJobs: payload,
      };
    case REMOVE_RECOMMENDED_JOBS:
      return {
        ...state,
        allRecommendedJobs: state.allRecommendedJobs.filter(
          (e) => e._id !== payload.employment
        ),
      };
    case CREATE_POSTULACION:
      return {
        ...state,
        empleos: state.empleos.filter((e) => e._id !== payload),
        allEmpleosFavoritos: state.allEmpleosFavoritos.filter(
          (e) => e._id !== payload
        ),
        allRecommendedJobs: state.allRecommendedJobs.filter(
          (e) => e._id !== payload
        ),
      };
    case SEARCH_EMPLEO:
      return {
        ...state,
        empleos: state.empleos.filter(
          (e) =>
            e.nombre.toLowerCase().includes(payload.toLowerCase()) ||
            e.ubicacion.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case UPDATE_EMPLEO:
      return {
        ...state,
        empleos: state.empleos.map((e) =>
          e._id === payload._id ? payload : e
        ),
      };
    case DELETE_EMPLEO:
      return {
        ...state,
        empleos: state.empleos.filter((e) => e._id !== payload._id),
      };
    case GET_EMPLEO_BY_ID:
      return {
        ...state,
        empleo: payload,
      };
    case GET_PRUEBAS:
      return {
        ...state,
        pruebasTerminadas: payload,
      };
    case ADD_PRUEBA:
      return {
        ...state,
        pruebasTerminadas: [...state.pruebasTerminadas, payload],
      };
    case ADD_EMPLEO_FAVORITO:
      return {
        ...state,
        allEmpleosFavoritos: [...state.allEmpleosFavoritos, payload],
        empleos: state.empleos.filter((e) => e._id !== payload._id),
      };
    case REMOVE_EMPLEO_FAVORITO:
      return {
        ...state,
        empleos: [...state.empleos, payload],
        allEmpleosFavoritos: state.allEmpleosFavoritos.filter(
          (e) => e._id !== payload._id
        ),
      };
    case GET_EXPERIENCIA_CLIENTE:
    case CREATE_EXPERIENCIA_CLIENTE:
      return {
        ...state,
        experienciaCliente: payload,
      };
    case GET_POSTULACION:
      return {
        ...state,
        allPostulaciones: payload,
      };
    case GET_ALL_EMPRESAS:
      return {
        ...state,
        allEmpresas: payload,
      };
    case GET_ALL_ESTUDIOS:
      return {
        ...state,
        allEstudios: payload,
      };
    case DELETE_POSTULACION:
      return {
        ...state,
        allPostulaciones: state.allPostulaciones.filter(
          (e) => e._id !== payload._id
        ),
      };
    case LOGOUT_USER:
      return nullState;
    case GET_POSTULACION_BY_EMPLEO:
      return {
        ...state,
        postuloEmpleo: payload,
      };
    case SEARCH_EMPLEOS_BY_FECHA:
      return {
        ...state,
        empleos: payload,
      };
    case GET_PLAN_SELECTION:
      return {
        ...state,
        planSelection: payload,
      };
    case GET_PLAN_SELECTION_CHOICE:
      return {
        ...state,
        getPlanSelection: payload,
      };
    case EXPIRED_EMPLOYMENTS:
      return {
        ...state,
        expiredEmployments: payload,
      };
    case VALIDATE_SELECTIONUSER_MAIL:
      return {
        ...state,
        selectionUserMail: payload,
      };
    case GET_PLATFORM_SCORE:
      return {
        ...state,
        platformScore: payload,
      }
    case UPDATE_POSTULACION_ENTREVISTA:
      return {
        ...state,
        allPostulaciones: state.allPostulaciones.map((e) => {
          if (e._id === payload._id) {
            return {
              ...e, entrevista: payload.entrevista
            }
          } else return e
        }),
      };
    /**Selection Evaluaciones */
    case CREATE_EVALUACION:
      return {
        ...state,
        evaluaciones: [payload, ...state.evaluaciones],
      };
    case GET_EVALUACION_BY_ID:
      return {
        ...state,
        evaluacion: payload,
        evaluacionTest: payload,
      };
    case GET_INVITACIONES_BY_EMAIL:
      return {
        ...state,
        allInvitacionesEvaluacion: payload,
      };
    case UPDATE_ESTADO_INVITACION:
      return {
        ...state,
        allInvitacionesEvaluacion : state.allInvitacionesEvaluacion.map((e) => {
          if(e._id === payload._id){
            return {
              ...e, estado: payload.estado
            }
          }else return e;
        }),
      };    
    default:
      return state;

  }
};
