import {
  CREATE_PLAN_DE_PAGO,
  GET_PLANES_DE_PAGO,
  GET_PLAN_DE_PAGO,
  UPDATE_PLAN_DE_PAGO,
  DELETE_PLAN_DE_PAGO,
  GET_CULQI_USER,
  GET_CULQI_SUBSCRIPTIONS,
  LOGOUT_USER
} from "../actions/Types";
let nullState = {
  planes: [],
  currentPlan: null,
  culqiUser: {},
  subscriptions: []
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_PLANES_DE_PAGO:
      return {
        ...state,
        planes: payload
      };
    case GET_PLAN_DE_PAGO:
      return {
        ...state,
        currentPlan: state.planes.filter(e => e.id === payload)[0]
      };
    case UPDATE_PLAN_DE_PAGO:
      return {
        ...state,
        planes: state.planes.map(e => (e.id === payload.id ? payload : e)),
        currentPlan:
          state.currentPlan && state.currentPlan.id === payload.id
            ? payload
            : state.currentPlan
      };
    case DELETE_PLAN_DE_PAGO:
      return {
        ...state,
        planes: state.planes.filter(e => e.id !== payload),
        currentPlan:
          state.currentPlan && state.currentPlan.id === payload
            ? null
            : state.currentPlan
      };
    case CREATE_PLAN_DE_PAGO:
      return { ...state, planes: [...state.planes, payload] };
    case GET_CULQI_USER:
      return {
        ...state,
        culqiUser: payload
      };
    case GET_CULQI_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: payload
      };
    case LOGOUT_USER:
      return {
        ...nullState,
        planes: state.planes
      };
    default:
      return state;
  }
};
