import axios from "axios";
// eslint-disable-next-line no-unused-vars
axios.defaults.withCredentials = true;

const render = () => {
  import("materialize-css/dist/css/materialize.min.css").then((x) =>
    require("./AppRenderer")
  );
};
render();
