//!deprecated
export const NAME_DONATELLO = "PERSONALIDAD";
export const NAME_DARTAGNAN = "INTELIGENCIA_III";
export const NAME_SOCIAL = "HABILIDADES_SOCIALES";
export const NAME_LIDERAZGOGOLEN = "LIDERAZGO";
export const NAME_MOSS = "ADAPTABILIDAD_PARA_GERENTES";
export const NAME_INTELIGENCIA = "INTELIGENCIA_I";
export const NAME_BAP7 = "RAZONAMIENTO_LOGICO";
export const NAME_BAP6 = "RAZONAMIENTO_NUMERICO";
export const NAME_CAMBIOS = "ADAPTABILIDAD";
//export const NAME_CAMBIOS = "FLEXIBILIDAD_COGNITVA";
export const NAME_D48VR = "INTELIGENCIA_II";
export const NAME_EMPRENDIMIENTO = "APTITUD_EMPRENDEDORA";
export const NAME_ASERTIVIDAD = "COMUNICACION_EFECTIVA";
export const NAME_APTITUD_VERBAL = "APTITUD_VERBAL";
