import {
  GET_JOBFAIR_CONFERENCES,
  GET_JOBFAIR_ENTERPRISES,
  GET_JOBFAIR_CONFIGURATION,
} from "../actions/Types";
let nullState = {
  configuration: null,
  enterprises: [],
  conferences: [],
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_JOBFAIR_CONFERENCES:
      return {
        ...state,
        conferences: payload,
      };
    case GET_JOBFAIR_ENTERPRISES:
      return {
        ...state,
        enterprises: payload,
      };
    case GET_JOBFAIR_CONFIGURATION:
      return {
        ...state,
        configuration: payload,
      };
    default:
      return state;
  }
};
