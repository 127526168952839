import { GET_WEBINAR_FROM_ID } from "../actions/Types";

let nullState = {
  info: {},
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_WEBINAR_FROM_ID:
      return { ...state, info: payload };
    default:
      return state;
  }
};
