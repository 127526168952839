export const PERFIL_DATOS = "PERFIL_DATOS";
export const PERFIL_EXPERIENCIA = "PERFIL_EXPERIENCIA";
export const CV_COMPLETO = "CV_COMPLETO";
export const POSTULAR = "POSTULAR";
export const PRUEBA_COMPLETADA = "PRUEBA_COMPLETADA";
export const REPORTE_GRATUITO = "REPORTE_GRATUITO";

export const stepsList = [
  {
    role: "SelectionUser",
    items: [
      {
        description:
          "Completar Nombre, Apellido, ID, toda la información básica.",
        name: PERFIL_DATOS,
      },
      {
        description: "Completar al menos una experiencia laboral.",
        name: PERFIL_EXPERIENCIA,
      },
      {
        description: "Subir tu CV",
        name: CV_COMPLETO,
      },
      {
        description: "Postular a un empleo",
        name: POSTULAR,
      },
      {
        description: "Rendir la prueba de personalidad e inteligencia",
        name: PRUEBA_COMPLETADA,
      },
      {
        description:
          "Descargar tu Reporte Gratuito para conocer tu perfil profesional",
        name: REPORTE_GRATUITO,
      },
    ],
  },
];
