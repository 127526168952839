//etapa
export const PROCESS_RECEIVING = "PROCESS_RECEIVING";
export const PROCESS_EVALUATING_TEST = "PROCESS_EVALUATING_TEST";
export const PROCESS_PRE_INTERVIEWING = "PROCESS_PRE_INTERVIEWING";
export const PROCESS_EVALUATING_REFERENCES = "PROCESS_EVALUATING_REFERENCES";
export const PROCESS_CLIENT_INTERVIEWING = "PROCESS_CLIENT_INTERVIEWING";

/** ESTADOS DEL PROCESO DE UN CANDIDATO => estado*/
export const CANTIDATE_UPLOADED_CV = "CANTIDATE_UPLOADED_CV"; //CVs
export const CANTIDATE_COMPLETED_TEST = "CANTIDATE_COMPLETED_TEST"; //Test
export const CANTIDATE_COMPLETED_PRE_INTERVIEW = `CANTIDATE_COMPLETED_PRE_INTERVIEW`; //Entrevistas
export const CANTIDATE_REFERENCES_SENT = "CANTIDATE_REFERENCES_SENT"; //Referencias
export const CANTIDATE_CLIENT_INTERVIEW_DONE = "CLIENT_INTERVIEW_DONE"; //Finalistas
export const CANTIDATE_HIRED = `CANTIDATE_HIRED`; //Contratados
/** Estados para las evaluaciones */
export const EN_PROCESO = "EN_PROCESO";
export const PRUEBAS_COMPLETADAS = "PRUEBAS_COMPLETADAS";
export const CERRADO = "CERRADO";
//eliminated from process
export const CANTIDATE_CLOSED_PROCESS = "CANTIDATE_CLOSED_PROCESS"; //Eliminados

// export const CANTIDATE_CLOSED_PROCESS = "CANTIDATE_CLOSED_PROCESS";
//  && match > 70%
