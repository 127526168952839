import { combineReducers } from "redux";
import user from "./User";
import property from "./Property";
import admin from "./Admin";
import equality from "./Equality";
import selection from "./Selection";
import wiki from "./Wiki";
import pagos from "./Pagos";
import cargos from "./Cargo";
import webinar from "./Webinar";
import nonmember from "./NonMember";
import subuser from "./Subusers";
import skills from "./Skills";
import ferialaboral from "./FeriaLaboral";

export default combineReducers({
  user,
  nonmember,
  subuser,
  property,
  equality,
  selection,
  admin,
  wiki,
  pagos,
  cargos,
  webinar,
  skills,
  ferialaboral,
});
