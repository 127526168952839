import {
  CREATE_USER_INFO,
  REGISTER_USER,
  GET_USER_FROM_TOKEN,
  UPDATE_USER,
  LOGIN_USER,
  UPDATE_USER_INFO,
  GET_POSTS,
  LOGOUT_USER,
  GET_USER_INFO,
  GET_USER,
  BUY_SUBSCRIPTION,
  UPDATE_USER_INFO_SELECT,
  UPDATE_USER_TODO_LIST,
  BUY_APP,
  DELETE_SUBSCRIPTION,
  DELETE_EMPRESA,
  DELETE_ESTUDIO,
  EXIST_FILE_CV,
  EXIST_FILE_FOTO,
  UPLOAD_PROFILE,
  GET_CV_DETAILS,
  UPDATE_SELECTIONUSER_MAIL,
  GET_BASE64_FILE,
} from "../actions/Types";
let nullState = {
  user: {},
  notifications: [],
  userInfo: null,
  cv: null,
  cvDetails: null,
  profile: false,
  base64: null,
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_CV_DETAILS:
      return {
        ...state,
        cvDetails: payload,
      };
    case CREATE_USER_INFO:
    case GET_USER_INFO:
    case UPDATE_USER_INFO:
    case UPDATE_USER_INFO_SELECT:
      return {
        ...state,
        userInfo: payload,
      };
    case UPDATE_USER_TODO_LIST:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          todoList: payload,
        },
      };
    case DELETE_EMPRESA:
      if (state.userInfo && state.userInfo.empresas) {
        return {
          ...state,
          userInfo: {
            ...state.userInfo,
            empresas: state.userInfo.empresas.filter(
              (e) => e._id !== payload._id
            ),
          },
        };
      } else {
        return state;
      }
    case DELETE_ESTUDIO:
      if (state.userInfo && state.userInfo.estudios) {
        return {
          ...state,
          userInfo: {
            ...state.userInfo,
            estudios: state.userInfo.estudios.filter(
              (e) => e._id !== payload._id
            ),
          },
        };
      } else {
        return state;
      }
    case UPDATE_SELECTIONUSER_MAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: payload,
        },
      };
    case REGISTER_USER:
    case GET_USER_FROM_TOKEN:
    case UPDATE_USER:
    case LOGIN_USER:
    case GET_USER:
    case BUY_APP:
      return {
        ...state,
        user: payload,
      };
    case GET_POSTS:
      return {
        ...state,
        notifications: payload,
      };
    case BUY_SUBSCRIPTION:
      return {
        ...state,
        user: payload,
      };
    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        user: payload,
      };
    case LOGOUT_USER:
      return nullState;
    case EXIST_FILE_CV:
      return {
        ...state,
        cv: payload,
      };
    case UPLOAD_PROFILE:
      return {
        ...state,
        profile: payload,
      };
    case EXIST_FILE_FOTO:
      return {
        ...state,
        profile: payload,
      };
    case GET_BASE64_FILE:
        return{
          ...state,
          base64: payload,
        }
    default:
      return state;
  }
};
