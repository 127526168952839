import axios from "axios";
import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_LOADING,
  CLEAR_LOADING,
  GET_WEBINAR_FROM_ID,
} from "./Types";
import { baseUrl } from "../../constants";

export const contactUs = (formData) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/users/contact", formData);
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const sendWebinarParticipant = (id, formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/v2/webinar/" + id,
    formData
  );
  const { message } = response.data;
  dispatch(setMessage(message));
  return response;
};

export const getWebinarFromId = (id) => async (dispatch) => {
  try {
    const response = await axios.get(baseUrl + "/api/v2/webinar/" + id);
    const { ok = false, message = "", data = null } = response.data;
    if (ok) dispatch({ type: GET_WEBINAR_FROM_ID, payload: data });
    else dispatch(setMessage(message));
  } catch (error) {
    dispatch(setMessage("Error al obtener usuario por token: " + error));
  }
};

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const dispatchMessage = (message) => async (dispatch) => {
  dispatch(setMessage(message));
};

export const clearMessage = () => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE });
};

export const setLoading = () => ({ type: SET_LOADING });

export const clearLoading = () => ({ type: CLEAR_LOADING });
