import axios from "axios";
import {
  CV_COMPLETO,
  PERFIL_DATOS,
  PERFIL_EXPERIENCIA,
} from "../../components/layout/menu/Steps";
import { baseUrl } from "../../constants";
import { setMessage } from "./Property";
import {
  ADD_APP,
  BUY_APP,
  CREATE_USER_INFO,
  DELETE_APP,
  DELETE_EMPRESA,
  DELETE_ESTUDIO,
  EDIT_APP,
  ENABLE_REACTOUR,
  EXIST_FILE_CV,
  EXIST_FILE_FOTO,
  GET_ALL_EMPRESAS,
  GET_ALL_ESTUDIOS,
  GET_CV_DETAILS,
  GET_PATH_LOCATION,
  GET_POSTS,
  GET_USER,
  GET_USERS,
  GET_USER_FROM_TOKEN,
  GET_USER_INFO,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  SEARCH_USERS,
  SET_USER,
  UPDATE_SELECTIONUSER_MAIL,
  UPDATE_USER,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SELECT,
  UPDATE_USER_TODO_LIST,
  UPLOAD_PROFILE,
} from "./Types";

export const setPreloadedState = async () => {
  const response = await axios.get(baseUrl + "/api/users");
  let preloadedState = {};
  if (response.data.ok && response.data.user) {
    preloadedState = { user: { user: response.data.user } };
  }
  return preloadedState;
};

export const buyApp = (data) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/pago", data);
  const { ok, message, user } = response.data;
  if (ok) {
    dispatch({
      type: BUY_APP,
      payload: user,
    });
    dispatch(setMessage("Se compro la aplicacion"));
  } else {
    dispatch(setMessage(message));
  }
};

export const addApp = (formData) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/users/app", formData);
  const { ok, message, user } = response.data;

  if (ok) {
    dispatch({ type: ADD_APP, payload: user });
    dispatch(setMessage("Se actualizo el usuario"));
  } else {
    dispatch(setMessage(message));
  }
};

export const editApp = (formData) => async (dispatch) => {
  const response = await axios.put(baseUrl + "/api/users/app", formData);
  const { ok, message, user } = response.data;

  if (ok) {
    dispatch({
      type: EDIT_APP,
      payload: user,
    });
    dispatch(setMessage("Se actualizo el usuario"));
  } else {
    dispatch(setMessage(message));
  }
};

export const deleteApp = (id) => async (dispatch) => {
  const response = await axios.delete(baseUrl + "/api/users/app?id=" + id);
  const { ok, message, user } = response.data;

  if (ok) {
    dispatch({
      type: DELETE_APP,
      payload: user,
    });
    dispatch(setMessage("Se actualizo el usuario"));
  } else {
    dispatch(setMessage(message));
  }
};

export const getUser = () => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/users");
  const { user, ok, message } = response.data;
  if (ok) {
    dispatch({ type: GET_USER, payload: user });
  } else {
    dispatch(setMessage(message));
  }
};

export const setUser = (id) => async (dispatch) => {
  dispatch({ type: SET_USER, payload: id });
};

export const getUsers = () => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/admin/users");
  const { data, message, ok } = response.data;

  if (ok) {
    dispatch({ type: GET_USERS, payload: data });
    dispatch(setMessage("Se cargaron los usuarios"));
  } else {
    dispatch(setMessage(message));
  }
};

export const searchUsers = (q) => async (dispatch) => {
  dispatch({ type: SEARCH_USERS, payload: q });
};

export const createUserInfo = (formData) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/users/info", formData);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: CREATE_USER_INFO, payload: data });
    dispatch(setMessage("Se agrego tus datos correctamente"));
  } else {
    dispatch(setMessage(message));
  }
};

export const userRegExpress =
  (formData, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(
      baseUrl + "/api/v2/users/reg-express",
      formData
    );
    const { ok, message, data } = response.data;
    if (ok) {
      dispatch({ type: CREATE_USER_INFO, payload: data });
      dispatch(setMessage("Se agrego tus datos correctamente"));
      if (retorno) return data;
    } else {
      dispatch(setMessage(message));
      if (retorno) return null;
    }
  };

export const registerUser =
  (formData, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(
      baseUrl + "/api/users/register",
      formData
    );

    console.log("REGISTER", response);
    const { user, ok, message } = response.data;
    if (ok) {
      dispatch({ type: REGISTER_USER, payload: user });
      dispatch(existCV());
      if (retorno) return user;
    } else {
      dispatch(setMessage(message));
      if (retorno) return null;
    }
  };

export const registerSubUser = (formData) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/users/register", formData);
  const { ok, message } = response.data;
  if (ok) {
    dispatch(setMessage("Se Creo el SubUsuario"));
  } else {
    dispatch(setMessage(message));
  }
};

export const getUserFromToken = (token) => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/users/reset/" + token);
  const { ok, message, data } = response.data;

  if (ok) {
    dispatch({ type: GET_USER_FROM_TOKEN, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const getUserFromEmailToken = (token) => async (dispatch) => {
  try {
    const response = await axios.get(baseUrl + "/api/v2/verify-email/" + token);

    const { ok, message } = response.data;
    if (ok) {
      dispatch(setMessage(message));
      return response;
    } else {
      dispatch(setMessage(message));
      return response;
    }
  } catch (error) {
    dispatch(setMessage("Error al verificar correo: " + error));
    // throw error;
  }
};

export const getVerifiedCaptchaToken = async (captchaToken) => {
  try {
    const response = await axios.post(
      baseUrl + "/api/v2/employments/verify-token",
      { token: captchaToken }
    );
    /* const { ok, message, verified } = response.data;
    if (!ok) {
      dispatch(setMessage(message));
    } */
    return response.data;
  } catch (error) {
    /*  dispatch(setMessage("Error al validar el captcha  " + error)); */
    return { ok: false, message: "Error al verificar el estado del correo" };
    // throw error;
  }
};
export const getUserEmailVerifiedStatus = async (userId) => {
  try {
    const response = await axios.get(
      baseUrl + "/api/v2/verify-email/user/" + userId
    );
    return response.data;
    /*  const { ok, message, verified } = response.data;
    if (ok) {
      return verified;
    } else {
      dispatch(setMessage(message));
      return response;
    } */
  } catch (error) {
    /* dispatch(
      setMessage("Error al validar email verificado de usuario  " + error)
    ); */
    return { ok: false, message: "Error al verificar el estado del correo" };
    // throw error;
  }
};

export const getUserEmailVerifiedStatusDispatch = (userId) => async(dispatch) => {
  try {
    const response = await axios.get(
      baseUrl + "/api/v2/verify-email/user/" + userId
    );
    return response.data;
  } catch (error) {
    dispatch(setMessage("Error al verificar el estado del correo electrónico"));
    return false
  }
};
export const resetPassword = (token, formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/reset/" + token,
    formData
  );
  const { ok, message } = response.data;

  if (ok) {
    dispatch(setMessage("Se cambio la contraseña"));
  } else {
    dispatch(setMessage(message));
  }
  return { ok, message };
};

export const updateUser = (formData) => async (dispatch) => {
  const response = await axios.put(baseUrl + "/api/users", formData);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: UPDATE_USER, payload: data });
    dispatch(setMessage("Se actualizo tus datos"));
  } else {
    dispatch(setMessage(message));
  }
};

export const uploadProfile = (file) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/upload/profile", file);
  const { ok, message } = response.data;
  if (ok) {
    dispatch({ type: UPLOAD_PROFILE, payload: ok });
    dispatch(setMessage("Se Subio la imagen de perfil"));
  } else {
    dispatch(setMessage(message));
  }
};

export const uploadCurriculumVitae = (file) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "/api/v2/files/uploads/curriculumVitae",
      file
    );
    if (response.data.ok) {
      dispatch(saveTodoStep(CV_COMPLETO));
      dispatch(setMessage("Archivo CV subido satisfactoriamente."));
    } else {
      dispatch(setMessage(response.message));
    }
  } catch (error) {
    if (error.response) {
      dispatch(setMessage(`${error.response.data.message}`));
    } else {
      dispatch(setMessage("Error al subir CV, comuníquese con HCP"));
    }
  }
};

export const existCV = (tipo) => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/upload/existFile", tipo);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: EXIST_FILE_CV, payload: data });
    dispatch(setMessage(message));
  } else {
    dispatch(setMessage(message));
  }
};

export const getCV = (userId) => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/v2/cvs/" + userId);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: GET_CV_DETAILS, payload: data });
    dispatch(setMessage(message));
  } else {
    dispatch(setMessage(message));
  }
};

export const existProfile = () => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/upload/existProfile");
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: EXIST_FILE_FOTO, payload: data });
    dispatch(setMessage(message));
  } else {
    dispatch(setMessage(message));
  }
};

export const forgotPassword = (formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/forgot-pass",
    formData
  );
  console.log(response);
  console.log(formData);
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const forgotUser = (formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/forgot-user",
    formData
  );
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const loginUser =
  (formData, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(baseUrl + "/api/users/login", formData);
    console.log("loginUser -> response", response);
    const { ok, message, user } = response.data;

    if (ok) {
      dispatch({ type: LOGIN_USER, payload: user });
      dispatch(existCV());
      dispatch({ type: ENABLE_REACTOUR, payload: false });
      if (retorno) return user;
    } else {
      dispatch(setMessage(message));
      if (retorno) return ok;
    }
  };

export const loginUserRecruitment = async (formData) => {
  const response = await axios.post(baseUrl + "/api/users/login", formData);
  console.log("loginUser -> response", response);
  return response.data;
  /* const { ok, message, user } = response.data;
  if (ok) { */
  /* dispatch({ type: LOGIN_USER, payload: user }); */
  /* return user;
  } else { */
  /*  dispatch(setMessage(message)); */
  /*   return null;
  } */
};

export const registerUserRecruitment = async (formData) => {
  const response = await axios.post(baseUrl + "/api/users/register", formData);
  /* const { user, ok, message } = response.data; */
  return response.data;
  /* if (ok) {
    dispatch({ type: REGISTER_USER, payload: user });
    return response.data;
  } else {
     dispatch(setMessage(message));
    return response.data;
  } */
};

export const updateUserInfo = (formData) => async (dispatch) => {
  console.log("FORM DATA", formData);
  const response = await axios.put(baseUrl + "/api/users/info", formData);
  const { ok, message, data } = response.data;

  if (ok) {
    dispatch(setMessage("Se agrego tus datos correctamente"));
    dispatch({ type: UPDATE_USER_INFO, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const updateUserInfoSelection =
  (formData, module = "") =>
  async (dispatch) => {
    const response = await axios.put(baseUrl + "/api/users/info", formData);
    const { ok, message, data } = response.data;
    if (ok) {
      dispatch({ type: UPDATE_USER_INFO_SELECT, payload: data });
      if (module === PERFIL_EXPERIENCIA || module === PERFIL_DATOS) {
        dispatch(saveTodoStep(module));
      }
      dispatch(setMessage("Se actualizaron tus datos correctamente"));
    } else {
      dispatch(setMessage(message));
    }
  };

export const getPosts = (payload) => async (dispatch) => {
  await dispatch({ type: GET_POSTS, payload });
};

export const getEmailInfo = () => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/analytics/get-email-info");
  const { ok, message, data } = response.data;
}

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_USER });
    const response = await axios.delete(baseUrl + "/api/users");
    const { ok, message } = response.data;
    if (ok) {
      /*   dispatch({ type: LOGOUT_USER }); */
    } else {
      // TODO:esto temporal hasta que se arregle lo de las cookies
      /* dispatch({ type: LOGOUT_USER }); */
      dispatch(setMessage(message));
    }
  } catch (error) {
    dispatch(setMessage(error.message));
    console.log("ERROR: -->", error);
  }
};

export const getUserInfo = () => async (dispatch) => {
  /**[TODO] */
  const response = await axios.get(baseUrl + "/api/users/info");
  const { ok, message, data } = response.data;

  if (ok) {
    dispatch({ type: GET_USER_INFO, payload: data });
    return data;
  } else {
    dispatch({ type: ENABLE_REACTOUR, payload: true });
    dispatch(setMessage(message));
  }
};

export const getAllEmpresas = () => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/users/empresas");
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: GET_ALL_EMPRESAS, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const deleteEmpresa = (id) => async (dispatch) => {
  const response = await axios.delete(baseUrl + "/api/users/empresa?id=" + id);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: DELETE_EMPRESA, payload: data });
    dispatch(setMessage("Se elimino la experiencia laboral"));
  } else {
    dispatch(setMessage(message));
  }
};

export const getAllEstudios = () => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/users/estudios");
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: GET_ALL_ESTUDIOS, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const deleteEstudio = (id) => async (dispatch) => {
  const response = await axios.delete(baseUrl + "/api/users/estudio?id=" + id);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: DELETE_ESTUDIO, payload: data });
    dispatch(setMessage("Se Elimno Formacion Academica"));
  } else {
    dispatch(setMessage(message));
  }
};

export const saveTodoStep = (name) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/users/steps/saveTodo", {
    step: name,
  });
  const { ok, message, data } = response.data;
  console.log(response);
  if (ok) {
    dispatch({ type: UPDATE_USER_TODO_LIST, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const verifyTodo = () => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/v2/users/steps/verifyTodo");
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: UPDATE_USER_TODO_LIST, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const saveHistory = (path) => async (dispatch) => {
  dispatch({ type: GET_PATH_LOCATION, payload: path });
  const response = await axios.post(baseUrl + "/api/v2/tracking", {
    path: path,
  });
  const { ok } = response.data;

  if (ok) {
  }
};

export const savePublicHistory = (path, ip) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/tracking/public", {
    path: path,
    ip: ip,
  });
  const { ok } = response.data;

  if (ok) {
  }
};

export const changeUserSelectionEmail = (email) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/v2/users/selection/change-email",
    {
      email: email,
    }
  );
  const { ok, message } = response.data;
  if (ok) {
    dispatch({ type: UPDATE_SELECTIONUSER_MAIL, payload: email });
  }
  dispatch(setMessage(message));
  return ok;
};

export const sendEmailConfirmation = () => async (dispatch) => {
  const response = await axios.get(
    baseUrl + "/api/v2/users/selection/send-validation-email"
  );
  const { ok, message } = response.data;
  dispatch(setMessage(message));
  return ok;
};

export const getJobPreferencesByToken = (token) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "/api/v2/users/selection/get-preferences",
      { token: token }
    );

    const { ok, message } = response.data;
    if (ok) {
      return response;
    } else {
      dispatch(setMessage(message));
      return response;
    }
  } catch (error) {
    dispatch(setMessage(error));
    // throw error;
  }
};

export const updateJobPreferencesByToken =
  (token, list) => async (dispatch) => {
    try {
      const response = await axios.post(
        baseUrl + "/api/v2/users/selection/update-preferences",
        { token: token, interestedList: list }
      );

      const { ok, message } = response.data;
      if (ok) {
        return response;
      } else {
        dispatch(setMessage(message));
        return response;
      }
    } catch (error) {
      dispatch(setMessage(error));
      // throw error;
    }
  };

  export const expressEnterpriseRegistration = (form) =>async (dispatch) => {
    try{
      const response = await axios.post(
        baseUrl + "/api/v2/users/reg-express-enterprise",form
      );
      const {ok, message, data} = response.data;
      console.log(response.data);
      if(!ok){
        return {ok, message}
      }
      return response.data;
    }catch(e){
      dispatch(setMessage(e));
    }
  }