export const GET_USER = "GET_USER";
export const ADD_APP = "ADD_APP";
export const BUY_APP = "BUY_APP";
export const EDIT_APP = "EDIT_APP";
export const DELETE_APP = "DELETE_APP";
export const CREATE_USER_INFO = "CREATE_USER_INFO";
export const REGISTER_USER = "REGISTER_USER";
export const GET_USER_FROM_TOKEN = "GET_USER_FROM_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const UPLOAD_PROFILE = "UPLOAD_PROFILE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_GOGLE = "LOGIN_GOGLE";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_SELECT = "UPDATE_USER_INFO_SELECT";
export const UPDATE_USER_TODO_LIST = "UPDATE_USER_TODO_LIST";
export const GET_POSTS = "GET_POSTS";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USERS = "GET_USERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const SET_USER = "SET_USER";
//Actualiza flag de subida de archivo
export const UPDATE_FLAG_EMPLEO = "UPDATE_FLAG_EMPLEO";
export const GET_USER_UPLOAD = "GET_USER_UPLOAD";
//Acrualizar base64 file
export const GET_BASE64_FILE = "GET_BASE64_FILE";

/**NONMEMBER TYPES */
export const GET_NONMEMBER_FROM_TOKEN = "GET_NONMEMBER_FROM_TOKEN";
export const GET_NONMEMBER_FROM_EMAIL = "GET_NONMEMBER_FROM_EMAIL";

/**WEBINAR TYPES */
export const GET_WEBINAR_FROM_ID = "GET_WEBINAR_FROM_ID";

/** SUBUSERS TYPES */
export const GET_SUBUSERS_BY_USER = "GET_SUBUSERS_BY_USER";

/**EMPLEOS TYPES */
export const SET_TXTSEARCH = "SET_TXTSEARCH";
export const GET_EMPLEOS = "GET_EMPLEOS";
export const GET_ALL_EMPLOYMENTS = "GET_ALL_EMPLOYMENTS";
export const GET_ALL_FILTERED_EMPLOYMENTS = "GET_ALL_FILTERED_EMPLOYMENTS";
export const CREATE_EMPLEO = "CREATE_EMPLEO";
export const UPDATE_EMPLEO = "UPDATE_EMPLEO";
export const DELETE_EMPLEO = "DELETE_EMPLEO";
export const GET_EMPLEO_BY_ID = "GET_EMPLEO_BY_ID";
export const UPDATE_ESTADO_EMPLEO = "UPDATE_ESTADO_EMPLEO";

export const DELETE_PRUEBA = "DELETE_PRUEBA";
export const UPDATE_STATE_PRUEBA = "UPDATE_STATE_PRUEBA";
export const GET_PRUEBAS = "GET_PRUEBAS";
export const ADD_PRUEBA = "ADD_PRUEBA";
export const CREATE_EXPERIENCIA_CLIENTE = "CREATE_EXPERIENCIA_CLIENTE";
export const GET_EXPERIENCIA_CLIENTE = "GET_EXPERIENCIA_CLIENTE";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";
export const SET_PLANILLA = "SET_PLANILLA";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const GET_EDITED_DOCUMENT = "GET_EDITED_DOCUMENT";
export const SET_EDITED_DOCUMENT = "SET_EDITED_DOCUMENT";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const APPROVE_EDITED = "APPROVE_EDITED";
export const SUBUSER_GET_PLANILLA = "SUBUSER_GET_PLANILLA";
export const CHANGE_PLANILLA = "CHANGE_PLANILLA";
export const GET_PLANILLA = "GET_PLANILLA";
export const SEARCH_PLANILLA = "SEARCH_PLANILLA";
export const UPLOAD_PLANILLA = "UPLOAD_PLANILLA";
export const ADD_BANDA = "ADD_BANDA";
export const GET_BANDA = "GET_BANDA";
export const GET_BANDA_POR_SEXO = "GET_BANDA_POR_SEXO";
export const UPDATE_BANDA = "UPDATE_BANDA";
export const DELETE_BANDA = "DELETE_BANDA";
export const CREATE_DOCUMENTS = "CREATE_DOCUMENTS";
export const CREATE_DOCUMENTS_POLITICAS = "CREATE_DOCUMENTS_POLITICAS";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const APPROVE_DOCUMENTS = "APPROVE_DOCUMENTS";
export const DELETE_DOCUMENTS = "DELETE_DOCUMENTS";
export const UPDATE_DOCUMENTS = "UPDATE_DOCUMENTS";
export const SEARCH_ADMIN_DOCUMENTO = "SEARCH_ADMIN_DOCUMENTO";
export const SEARCH_ADMIN_CANDIDATO = "SEARCH_ADMIN_CANDIDATO";
export const SEARCH_ADMIN_EMPRESA = "SEARCH_ADMIN_EMPRESA";
export const SEARCH_ADMIN_EMPLEO = "SEARCH_ADMIN_EMPLEO";
export const GET_ALL_DOCUMENTOS = "GET_ALL_DOCUMENTOS";
export const GET_ALL_CANDIDATOS = "GET_ALL_CANDIDATOS";
export const GET_ALL_PRUEBAS_CANDIDATO = "GET_ALL_PRUEBAS_CANDIDATO";
export const SET_SUSTENTO = "SET_SUSTENTO";
export const SEARCH_SUSTENTO = "SEARCH_SUSTENTO";
export const UPDATE_SUSTENTO = "UPDATE_SUSTENTO";
export const UPDATE_SUSTENTOS = "UPDATE_SUSTENTOS";
export const SET_ADMIN_SUSTENTO = "SET_ADMIN_SUSTENTO";
export const SEARCH_ADMIN_SUSTENTO = "SEARCH_ADMIN_SUSTENTO";
export const GET_ALL_SUSTENTOS = "GET_ALL_SUSTENTOS";
export const SEARCH_EMPLEO = "SEARCH_EMPLEO";
export const EXPIRED_EMPLOYMENTS = "EXPIRED_EMPLOYMENTS";
// Selection
export const GET_ALL_EMPRESAS = "GET_ALL_EMPRESAS";
export const DELETE_EMPRESA = "DELETE_EMPRESA";
export const GET_ALL_ESTUDIOS = "GET_ALL_ESTUDIOS";
export const DELETE_ESTUDIO = "DELETE_ESTUDIO";
export const GET_ALL_EMPLEOS_FAVORITOS = "GET_ALL_EMPLEOS_FAVORITOS";
export const ADD_EMPLEO_FAVORITO = "ADD_EMPLEO_FAVORITO";
export const REMOVE_EMPLEO_FAVORITO = "REMOVE_EMPLEO_FAVORITO";
export const SEARCH_EMPLEOS_BY_FECHA = "SEARCH_EMPLEOS_BY_FECHA";
export const SEARCH_EMPLEOS_BY_UBICACION = "SEARCH_EMPLEOS_BY_UBICACION";
export const GET_PLAN_SELECTION = "GET_PLAN_SELECTION";
export const GET_PLAN_SELECTION_CHOICE = "GET_PLAN_SELECTION_CHOICE";
export const VALIDATE_SELECTIONUSER_MAIL = "VALIDATE_SELECTIONUSER_MAIL";
export const UPDATE_SELECTIONUSER_MAIL = "UPDATE_SELECTIONUSER_MAIL";
export const GET_PLATFORM_SCORE = "GET_PLATFORM_SCORE"; 
// Recommended jobs
export const GET_ALL_RECOMMENDED_JOBS = "GET_ALL_RECOMMENDED_JOBS";
export const REMOVE_RECOMMENDED_JOBS = "REMOVE_RECOMMENDED_JOBS";

// Admin
export const GET_EMPRESAS = "GET_EMPRESAS";
export const GET_EMPLEOS_BY_EMPRESA = "GET_EMPLEOS_BY_EMPRESA";
export const GET_EMPLEOS_ACTIVE_BY_EMPRESA = "GET_EMPLEOS_ACTIVE_BY_EMPRESA";
export const GET_EMPLEOS_BY_ESTADO = "GET_EMPLEOS_BY_ESTADO";
export const SEARCH_ETAPA_EMPLEO = "SEARCH_ETAPA_EMPLEO";
export const UPDATE_POSTULACION_ENTREVISTA = "UPDATE_POSTULACION_ENTREVISTA"
export const GET_POSTULACIONES_BY_EMPLEO = "GET_POSTULACIONES_BY_EMPLEO";
export const GET_ALL_POSTULATIONS_BY_EMPLOYMENT = `GET_ALL_POSTULATIONS_BY_EMPLOYMENT`;
export const GET_RESET_POSTULACIONES_EMPLEO = "GET_RESET_POSTULACIONES_EMPLEO";
export const GET_PUNTAJE_PUESTO = "GET_PUNTAJE_PUESTO";
export const GET_EXIST_ORGANIGRAMA = "GET_EXIST_ORGANIGRAMA";
export const GET_HISTORIAL_REPORTE = "GET_HISTORIAL_REPORTE";
export const CREATE_PUNTAJE_PUESTO = "CREATE_PUNTAJE_PUESTO";
export const LOADING_TABLE = "LOADING_TABLE";
export const LOADING_TABLE_B2B = "LOADING_TABLE_B2B";
export const STATE_TABLE = "STATE_TABLE";
export const ENABLE_REACTOUR = "ENABLE_REACTOUR";

// Postulaciones
export const CREATE_POSTULACION = "CREATE_POSTULACION";
export const GET_POSTULACION = "GET_POSTULACION";
export const SET_POSTULACIONES_LOADING = "SET_POSTULACIONES_LOADING";
export const DELETE_POSTULACION = "DELETE_POSTULACION";
export const GET_POSTULACION_BY_EMPLEO = "GET_POSTULACION_BY_EMPLEO";
export const UPDATE_POSTULATION = "UPDATE_POSTULATION";
export const COUNT_STAGE_POSTULATION_BY_JOB = "COUNT_STAGE_POSTULATION_BY_JOB";

export const FETCH_POSTULATIONS = "FETCH_POSTULATIONS";
export const FETCH_POSTULATIONS_SUCCESS = "FETCH_POSTULATIONS_SUCCESS";
export const FETCH_POSTULATIONS_ERROR = "FETCH_POSTULATIONS_ERROR";

export const UPDATE_POSTULATIONS = "UPDATE_POSTULATIONS";
export const UPDATE_POSTULATIONS_SUCCESS = "UPDATE_POSTULATIONS_SUCCESS";
export const UPDATE_POSTULATIONS_ERROR = "UPDATE_POSTULATIONS_ERROR";

export const UPDATE_POSTULACION_EMPLEO = "UPDATE_POSTULACION_EMPLEO";

//Factores
export const GET_FACTOR = "GET_FACTOR";
export const UPDATE_FACTOR = "UPDATE_FACTOR";
export const ADD_FACTOR = "ADD_FACTOR";
export const DELETE_FACTOR = "DELETE_FACTOR";
export const SET_FACTOR = "SET_FACTOR";

//Reporte
export const GET_REPORTE = "GET_REPORTE";
export const SEND_REPORTE = "SEND_REPORTE";

//Uploads
export const UPLOAD_PONDERACION = "UPLOAD_PONDERACION";

//Wiki
export const GET_WIKI = "GET_WIKI";
export const CREATE_WIKI = "CREATE_WIKI";
export const UPDATE_WIKI = "UPDATE_WIKI";
export const DELETE_WIKI = "DELETE_WIKI";

//Planes de Pago
export const CREATE_PLAN_DE_PAGO = "CREATE_PLAN_DE_PAGO";
export const GET_PLANES_DE_PAGO = "GET_PLANES_DE_PAGO";
export const GET_PLAN_DE_PAGO = "GET_PLAN_DE_PAGO";
export const UPDATE_PLAN_DE_PAGO = "UPDATE_PLAN_DE_PAGO";
export const DELETE_PLAN_DE_PAGO = "DELETE_PLAN_DE_PAGO";
export const BUY_SUBSCRIPTION = "BUY_SUBSCRIPTION";
export const GET_CULQI_USER = "GET_CULQI_USER";
export const GET_CULQI_SUBSCRIPTIONS = "GET_CULQI_SUBSCRIPTIONS";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";

/**CARGO DE PAGOS CULQUI */
export const CREATE_CARGO_PAGO = "CREATE_CARGO_PAGO";
export const GET_CARGOS_DE_PAGO = "GET_CARGOS_DE_PAGO";
export const GET_CARGO_DE_PAGO = "GET_CARGO_DE_PAGO";
export const UPDATE_CARGO_DE_PAGO = "UPDATE_CARGO_DE_PAGO";
export const DELETE_CARGO_DE_PAGO = "DELETE_CARGO_DE_PAGO";
export const BUY_CARGO = "BUY_CARGO";
export const GET_CULQUI_CARGO = "GET_CULQUI_CARGO";
export const DELETE_CARGO = "DELETE_CARGO";

//Keywords
export const GET_KEYWORDS = "GET_KEYWORDS";
export const SET_KEYWORD = "SET_KEYWORD";
export const CREATE_KEYWORDS = "CREATE_KEYWORDS";
export const DELETE_KEYWORD = "DELETE_KEYWORD";
export const UPDATE_KEYWORD = "UPDATE_KEYWORD";
export const GET_KEYWORDS_BY_COMPANY = "GET_KEYWORDS_BY_COMPANY";

// CREATE ARCHIVOS
export const CREATE_POLITICA = "CREATE_POLITICA";
export const GET_DESCRIPCION_EMPRESA = "GET_DESCRIPCION_EMPRESA";
export const EXIST_FILE_CV = "EXIST_FILE_CV";
export const EXIST_FILE_FOTO = "EXIST_FILE_FOTO";

// CV
export const GET_CV_DETAILS = "GET_CV_DETAILS";

//Candidates
export const GET_BEST_CANDIDATES = "GET_BEST_CANDIDATES";
export const REMOVE_BEST_CANDIDATES = "REMOVE_BEST_CANDIDATES";

//Skills
export const GET_PATH_LOCATION = "GET_PATH_LOCATION";

//Feria Laboral
export const GET_JOBFAIR_ENTERPRISES = "GET_JOBFAIR_ENTERPRISES";
export const GET_JOBFAIR_CONFERENCES = "GET_JOBFAIR_CONFERENCES";
export const GET_JOBFAIR_CONFIGURATION = "GET_JOBFAIR_CONFIGURATION";

//Evaluaciones
export const CREATE_EVALUACION = "CREATE_EVALUACION";
export const LOADING_TABLE_EVALUATION = "LOADING_TABLE_LOADING_TABLE_EVALUATION";
export const GET_EVALUACIONES_BY_EMPRESA = "GET_EVALUACIONES_BY_EMPRESA";
export const GET_EVALUACION_BY_ID = "GET_EVALUACION_BY_ID";

//Invitaciones a Evaluaciones
export const GET_INVITACIONES_BY_EVALUACION = "GET_INVITACIONES_BY_EVALUACION";
export const GET_INVITACIONES_BY_EMAIL = "GET_INVITACIONES_BY_EMAIL";
export const UPDATE_ESTADO_INVITACION = "UPDATE_ESTADO_INVITACION";
export const GET_INVITATION_BY_ID = "GET_INVITATION_BY_ID";