import {
  GET_USERS,
  SEARCH_USERS,
  SET_USER,
  LOGOUT_USER,
  SEARCH_ADMIN_DOCUMENTO,
  SEARCH_ADMIN_SUSTENTO,
  SEARCH_ADMIN_CANDIDATO,
  SEARCH_ADMIN_EMPRESA,
  SEARCH_ADMIN_EMPLEO,
  GET_ALL_SUSTENTOS,
  GET_ALL_DOCUMENTOS,
  GET_ALL_CANDIDATOS,
  GET_ALL_PRUEBAS_CANDIDATO,
  ADD_APP,
  EDIT_APP,
  DELETE_APP,
  DELETE_PRUEBA,
  UPDATE_STATE_PRUEBA,
  GET_KEYWORDS,
  SET_KEYWORD,
  CREATE_KEYWORDS,
  DELETE_KEYWORD,
  UPDATE_KEYWORD,
  GET_EMPRESAS,
  GET_EMPLEOS_BY_EMPRESA,
  GET_EMPLEOS_ACTIVE_BY_EMPRESA,
  LOADING_TABLE,
  LOADING_TABLE_B2B,
  UPDATE_POSTULACION_EMPLEO,
  UPDATE_POSTULACION_ENTREVISTA,
  GET_POSTULACIONES_BY_EMPLEO,
  GET_RESET_POSTULACIONES_EMPLEO,
  SEARCH_ETAPA_EMPLEO,
  GET_PUNTAJE_PUESTO,
  GET_HISTORIAL_REPORTE,
  GET_EMPLEOS_BY_ESTADO,
  COUNT_STAGE_POSTULATION_BY_JOB,
  GET_ALL_POSTULATIONS_BY_EMPLOYMENT,
  GET_KEYWORDS_BY_COMPANY,
  GET_BEST_CANDIDATES,
  REMOVE_BEST_CANDIDATES,
  STATE_TABLE,
  ENABLE_REACTOUR,
  //actualiza el flag de archivo
  UPDATE_FLAG_EMPLEO,
  GET_USER_UPLOAD,
  //evaluaciones
  GET_EVALUACIONES_BY_EMPRESA,
  LOADING_TABLE_EVALUATION,
  GET_INVITACIONES_BY_EVALUACION,
  GET_INVITATION_BY_ID,
} from "../actions/Types";
let nullState = {
  users: [],
  currentUser: null,
  loadingTable: false,
  loadingTableB2B: false,
  loadingTableEvaluacion: false,
  allSustentos: [],
  allDocumentos: [],
  allCandidatos: [],
  allPruebasCandidato: [],
  allEmpresas: [],
  allEmpleosActivosEmpresa: [],
  allEmpleosEmpresa: [],
  allEmpleosEmpresaContador: [],
  allPostulacionesEmpleo: [],
  postulacionesEmpleo: [],
  keywords: [],
  currentKeyword: {},
  allPuntajePuestos: [],
  allPuntajes: [],
  allHistorialReporte: [],
  countStagePostulationByJob: {},
  allPostulationsByEmployment: [],
  allKeywordsByCompany: [],
  bestCandidates: [],
  allUserUpload: [],
  allEvaluacionesEmpresa: [],
  allInvitacionesEvaluacion: null,
  invitation: null,
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case EDIT_APP:
    case DELETE_APP:
    case ADD_APP:
      return {
        ...state,
        users: state.users.map((e) => (e._id === payload._id ? payload : e)),
        currentUser: payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case SEARCH_USERS:
      return {
        ...state,
        users: state.users.filter(
          (e) =>
            (e.username.toLowerCase().includes(payload.toLowerCase()) ||
              (e.empresa &&
                e.empresa.toLowerCase().includes(payload.toLowerCase()))) &&
            e
        ),
      };
    case SET_USER:
      return {
        ...state,
        currentUser: state.users.filter((user) => user._id === payload)[0],
      };
    case SEARCH_ADMIN_DOCUMENTO:
      return {
        ...state,
        allDocumentos: state.allDocumentos.filter(
          (e) =>
            e.empresa.toLowerCase().includes(payload.toLowerCase()) ||
            e.nombre.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case SEARCH_ADMIN_CANDIDATO:
      return {
        ...state,
        allCandidatos: state.allCandidatos.filter(
          (e) =>
            e.nombre.toLowerCase().includes(payload.toLowerCase()) ||
            e.paterno.toLowerCase().includes(payload.toLowerCase()) ||
            e.materno.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case SEARCH_ADMIN_EMPRESA:
      return {
        ...state,
        allEmpresas: state.allEmpresas.filter((e) =>
          e.nombre.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case SEARCH_ADMIN_EMPLEO:
      return {
        ...state,
        allEmpleosEmpresa: state.allEmpleosEmpresa.filter((e) =>
          e.nombre.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case SEARCH_ADMIN_SUSTENTO:
      return {
        ...state,
        allSustentos: state.allSustentos.filter(
          (e) =>
            e.empresa.toLowerCase().includes(payload.toLowerCase()) ||
            e.codigo.toLowerCase().includes(payload.toLowerCase()) ||
            e.cargo.toLowerCase().includes(payload.toLowerCase()) ||
            e.banda.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case GET_ALL_SUSTENTOS:
      return {
        ...state,
        allSustentos: payload,
      };
    case GET_ALL_DOCUMENTOS:
      return {
        ...state,
        allDocumentos: payload,
      };
    case GET_ALL_CANDIDATOS:
      return {
        ...state,
        allCandidatos: payload,
      };
    case GET_ALL_PRUEBAS_CANDIDATO:
      return {
        ...state,
        allPruebasCandidato: payload,
      };
    case UPDATE_STATE_PRUEBA:
    case DELETE_PRUEBA:
      if (state.allPruebasCandidato) {
        return {
          ...state,
          allPruebasCandidato: state.allPruebasCandidato.filter(
            (e) => e._id !== payload._id
          ),
        };
      } else {
        return state;
      }
    case GET_KEYWORDS:
      return {
        ...state,
        keywords: payload,
      };
    case GET_KEYWORDS_BY_COMPANY:
      return {
        ...state,
        allKeywordsByCompany: payload,
      };
    case SET_KEYWORD:
      return {
        ...state,
        currentKeyword: state.keywords.filter((e) => e._id === payload)[0],
      };
    case CREATE_KEYWORDS:
      return {
        ...state,
        keywords: [...state.keywords, payload],
      };
    case DELETE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.filter((e) => e._id !== payload._id),
      };
    case UPDATE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.map((e) =>
          e._id === payload._id ? payload : e
        ),
      };
    case GET_EMPRESAS:
      return {
        ...state,
        allEmpresas: payload,
      };
    case GET_EMPLEOS_BY_EMPRESA:
      return {
        ...state,
        allEmpleosEmpresa: payload,
        allEmpleosEmpresaContador: payload,
      };
    case LOADING_TABLE:
      return {
        ...state,
        loadingTable: payload,
      };
    case GET_EMPLEOS_ACTIVE_BY_EMPRESA:
      return {
        ...state,
        allEmpleosActivosEmpresa: payload,
      };
    case LOADING_TABLE_B2B:
      return {
        ...state,
        loadingTableB2B: payload,
      };
    case STATE_TABLE:
      return {
        ...state,
        stateTable: payload,
      };
    case GET_EMPLEOS_BY_ESTADO:
      return {
        ...state,
        allEmpleosEmpresa: state.allEmpleosEmpresaContador.filter(
          (e) => e.estado === payload
        ),
      };
    case UPDATE_POSTULACION_EMPLEO:
      let nameLista = `allPostulationsState${payload.etapaPostulacion}`;
      let allPostulacionesEmpleo = { ...state.allPostulacionesEmpleo };
      console.log(allPostulacionesEmpleo);
      let index = allPostulacionesEmpleo[nameLista].findIndex(
        (item) => item.idPostulation === payload.idPostulation
      );
      allPostulacionesEmpleo[nameLista][index] = {
        ...allPostulacionesEmpleo[nameLista][index],
        visto: true,
      };
      return { ...state, allPostulacionesEmpleo };
    case GET_POSTULACIONES_BY_EMPLEO:
      return {
        ...state,
        allPostulacionesEmpleo: payload,
        postulacionesEmpleo: payload,
      };
    case GET_ALL_POSTULATIONS_BY_EMPLOYMENT:
      return {
        ...state,
        allPostulationsByEmployment: payload,
      };
    case COUNT_STAGE_POSTULATION_BY_JOB:
      return {
        ...state,
        countStagePostulationByJob: payload,
      };
    case GET_RESET_POSTULACIONES_EMPLEO:
      return {
        ...state,
        allPostulacionesEmpleo: payload,
        postulacionesEmpleo: payload,
      };
    case SEARCH_ETAPA_EMPLEO:
      return {
        ...state,
        postulacionesEmpleo: state.allPostulacionesEmpleo.filter((e) =>
          e.etapa.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    case GET_PUNTAJE_PUESTO:
      return {
        ...state,
        allPuntajePuestos: payload,
        allPuntajes: payload.map((p) => p.puntajes),
      };
    case GET_HISTORIAL_REPORTE:
      return {
        ...state,
        allHistorialReporte: payload,
      };
    case GET_BEST_CANDIDATES:
      return {
        ...state,
        bestCandidates: payload,
      };
    case REMOVE_BEST_CANDIDATES:
      return {
        ...state,
        bestCandidates: [],
      };
    case LOGOUT_USER:
      return nullState;
    case ENABLE_REACTOUR:
      return {
        ...state,
        enableReactTour: payload,
      };
    case UPDATE_FLAG_EMPLEO:
      return {
        ...state,
        allEmpleosEmpresa: state.allEmpleosEmpresa.map(e => { if (payload === e._id) e.archivoSubido = true; return e })
      }
    case GET_USER_UPLOAD:
      return {
        ...state,
        allUserUpload: payload,
      }
    //evaluaciones  
    case LOADING_TABLE_EVALUATION:
      return {
        ...state,
        loadingTableEvaluacion: payload,
      };
    case GET_EVALUACIONES_BY_EMPRESA:
      return {
        ...state,
        allEvaluacionesEmpresa: payload,
      };
    case GET_INVITACIONES_BY_EVALUACION:
      return{
        ...state,
        allInvitacionesEvaluacion: payload,
      }
    case GET_INVITATION_BY_ID:
      return{
        ...state,
        invitation: payload,
      }      
    default:
      return state;
  }
};
