import { GET_NONMEMBER_FROM_TOKEN, GET_NONMEMBER_FROM_EMAIL } from "../actions/Types";

let nullState = {
  supervisor: {},
  nonUser: null,
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_NONMEMBER_FROM_TOKEN:
      return { ...state, supervisor: payload };
    case GET_NONMEMBER_FROM_EMAIL:
      return { ...state, nonUser: payload };  
    default:
      return state;
  }
};
