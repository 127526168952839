/** @description
 *  Estados para el schema de Empleos*/
export const ACTIVO = "ACTIVO";
export const OFERTA_CERRADA = "OFERTA CERRADA";
export const PAUSADO = "PAUSADO";
export const CANCELADO = "CANCELADO";
export const ELIMINADO = "ELIMINADO";

/* NOT A STATE */
export const EXPIRADO = "EXPIRADO";
