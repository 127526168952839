import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_LOADING,
  CLEAR_LOADING,
  LOGOUT_USER
} from "../actions/Types";
let nullState = {
  message: null,
  loading: false
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: payload
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case CLEAR_LOADING:
      return {
        ...state,
        loading: false
      };
    case LOGOUT_USER:
      return nullState;
    default:
      return state;
  }
};
